<template>
  <fw-panel title="Informação pessoal">
    <LoadingPlaceholder v-if="loading" class="my-5" />
    <div v-else class="bg-white rounded-2xl p-5 flex flex-col gap-4">
      <div>
        <fw-label>{{ $t('name') }}</fw-label>
        <b-input v-if="editable" v-model="user.full_name" disabled></b-input>
        <fw-panel-info v-else size="normal" clean>
          {{ user.full_name }}
        </fw-panel-info>
      </div>

      <div>
        <fw-label>{{ $t('email') }}</fw-label>
        <b-input v-if="editable" v-model="user.email" disabled></b-input>
        <fw-panel-info v-else size="normal" clean>
          {{ user.email }}
        </fw-panel-info>
      </div>

      <div v-if="league?.type == 'alumni'">
        <fw-label>{{ $t('studentNumber') }}</fw-label>
        <div v-if="editable">
          <TextInput
            v-model="userData.student_number"
            :minlength="3"
            :maxlength="255"
            :class="{
              error: $v.userData.student_number.$error
            }"
          ></TextInput>
          <fw-tip v-if="$v.userData.student_number.$error" error>
            <span v-if="!$v.userData.student_number.required">{{ $t('errors.required.studentNumber') }}</span>
            <span v-else>{{ $t('errors.invalid.studentNumber') }}</span>
          </fw-tip>
        </div>
        <fw-panel-info v-else size="normal" clean>
          {{ userData.student_number }}
        </fw-panel-info>
      </div>

      <div v-if="league?.type == '2is'">
        <fw-label>{{ $t('companyName') }}</fw-label>
        <div v-if="editable">
          <TextInput
            v-model="userData.company_name"
            :minlength="3"
            :maxlength="255"
            :class="{
              error: $v.userData.company_name.$error
            }"
          ></TextInput>
          <fw-tip v-if="$v.userData.company_name.$error" error>
            <span v-if="!$v.userData.company_name.required">{{ $t('errors.required.companyName') }}</span>
            <span v-else>{{ $t('errors.invalid.companyName') }}</span>
          </fw-tip>
        </div>
        <fw-panel-info v-else size="normal" clean>
          {{ userData.company_name }}
        </fw-panel-info>
      </div>

      <div v-if="league?.type == 'inter-residences'">
        <fw-label>{{ $t('residenceName') }}</fw-label>
        <div v-if="editable">
          <TextInput
            v-model="userData.residence_name"
            :minlength="3"
            :maxlength="255"
            :class="{
              error: $v.userData.residence_name.$error
            }"
          ></TextInput>
          <fw-tip v-if="$v.userData.residence_name.$error" error>
            <span v-if="!$v.userData.residence_name.required">{{ $t('errors.required.residenceName') }}</span>
            <span v-else>{{ $t('errors.invalid.residenceName') }}</span>
          </fw-tip>
        </div>
        <fw-panel-info v-else size="normal" clean>
          {{ userData.residence_name }}
        </fw-panel-info>
      </div>

      <div>
        <fw-label>{{ $t('birthday') }}</fw-label>
        <b-datepicker v-if="editable" v-model="userData.birthday"> </b-datepicker>
        <fw-panel-info v-else size="normal" clean>
          {{ userData.birthday | formatDate }}
        </fw-panel-info>

        <fw-tip v-if="$v.userData.birthday.$error" error>
          <span v-if="!$v.userData.birthday.min">{{
            $t('errors.min.birthday', { date: $options.filters.formatDate(minBirthdayDate) })
          }}</span>
          <span v-else-if="!$v.userData.birthday.max">{{
            $t('errors.max.birthday', { date: $options.filters.formatDate(maxBirthdayDate) })
          }}</span>
          <span v-else>{{ $t('errors.required.birthday') }}</span>
        </fw-tip>
        <fw-tip v-if="birthdayError != null" error>
          {{ birthdayError }}
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('gender') }}</fw-label>
        <b-select v-if="editable" v-model="userData.gender" placeholder="Selecione um género">
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="U">Outros</option>
        </b-select>
        <fw-panel-info v-else size="normal" clean>
          {{ userData.gender == 'M' ? 'Masculino' : userData.gender == 'F' ? 'Feminino' : 'Outros' }}
        </fw-panel-info>
        <fw-tip v-if="$v.userData.gender.$error" error>
          <span v-if="!$v.userData.gender.required">{{ $t('errors.required.gender') }}</span>
          <span v-else
            >Este torneio apenas permite participantes do género {{ genders[tournament.gender][language] }}</span
          >
        </fw-tip>
      </div>

      <div v-if="editable" class="flex gap-4">
        <div>
          <fw-label>{{ $t('phoneCountry') }}</fw-label>
          <PhoneCountriesSelect
            :placeholder="$t('phoneCountry')"
            :input="userData.phone_country.key"
            @update="phoneCountryChanged($event)"
          />
        </div>
        <div>
          <fw-label>{{ $t('phone') }}</fw-label>
          <NumberInput
            v-model="userData.phone_number"
            :placeholder="$t('phone')"
            :maxlength="20"
            :class="{
              error: $v.userData.phone_number.$error
            }"
          ></NumberInput>
          <fw-tip v-if="$v.userData.phone_number.$error" error>
            <span v-if="!$v.userData.phone_number.required">{{ $t('errors.required.phoneNumber') }}</span>
            <span v-else>{{ $t('errors.invalid.phoneNumber') }}</span>
          </fw-tip>
          <fw-tip v-if="$v.userData.phone_country.$error" error>
            <span v-if="!$v.userData.phone_country.required">{{ $t('errors.required.phoneCountry') }}</span>
            <span v-else>{{ $t('errors.invalid.phoneCountry') }}</span>
          </fw-tip>
        </div>
      </div>
      <div v-else class="flex gap-4">
        <div>
          <fw-label>{{ $t('phone') }}</fw-label>
          <fw-panel-info size="normal" clean>
            {{ userData.phone_number }}
          </fw-panel-info>
        </div>
      </div>

      <div>
        <b-field>
          <b-checkbox v-model="acceptTerms" :disabled="!editable" @input="handleTermsInput">
            <div class="text-sm leading-4">{{ $t('acceptTerms') }}</div>
          </b-checkbox>
        </b-field>
        <fw-tip v-if="$v.acceptTerms.$error" error>
          {{ $t('errors.required.acceptTerms') }}
        </fw-tip>
      </div>

      <!-- <div>
        <div class="mt-4">
          <fw-label>{{ $t('documents') }}</fw-label>
          <div v-if="filteredFiles.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="file in filteredFiles"
              :key="file.key"
              :allow-classified="false"
              :file="file"
              :can-edit="editable"
              :can-remove="editable"
              @remove="removeFile(file)"
              @download="downloadFile(file)"
            />
          </div>
          <fw-panel-info size="normal" v-else type="basic" class="text-center text-gray-500">
            Sem comprovativos enviados
          </fw-panel-info>
          <PathUploader
            v-if="editable"
            :label="'Enviar comprovativo'"
            :is-docked="true"
            layout="minimal"
            reference-id="requirements_proof"
            allowed="all"
            :clear-after="true"
            input-id="requirements_proof"
            :files.sync="uploadFiles"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code="requirements_proof"
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded($event)"
          />
          <fw-tip v-if="$v.userData.files.$error && editable" error>
            É necessário que envie o(s) comprovativo(s) de cumprimento de requisitos para se inscrever nesta liga.
          </fw-tip>
        </div>
      </div> -->
    </div>

    <ModalTermsAndConditions
      ref="termsAndConditions"
      @accept="acceptTermsAndConditions"
      @decline="declineTermsAndConditions"
    ></ModalTermsAndConditions>
  </fw-panel>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
// import PathUploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
// import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ModalTermsAndConditions from '@/components/modals/ModalTermsAndConditions'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { required, maxLength, requiredIf, maxValue, minValue, helpers } from 'vuelidate/lib/validators'
import { GENDERS } from '@/utils/index.js'

export default {
  name: 'PanelSignupPersinalInfo',
  components: {
    NumberInput,
    TextInput,
    PhoneCountriesSelect,
    // PathUploader,
    // RecordFileEntry,
    LoadingPlaceholder,
    ModalTermsAndConditions
  },
  props: {
    editionKey: {
      type: String
    },
    league: {
      type: Object
    },
    tournament: {
      type: Object
    },
    management: {
      type: Boolean,
      default: false
    },
    userKey: {
      type: String
    },
    teamKey: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    checkTerms: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadFiles: [],
      loading: true,
      saving: false,
      userData: {
        birthday: null,
        gender: null,
        phone_country: {
          key: 'PT',
          code: '+351',
          title: 'Portugal'
        },
        phone_number: null,
        residence_name: null,
        company_name: null,
        student_number: null,
        files: []
      },
      maxBirthdayDate: Dates.now()
        .subtract(17, 'year')
        .toDate(),
      minBirthdayDate: Dates.now()
        .subtract(100, 'year')
        .toDate(),
      errors: [],
      validatFiles: false,
      genders: GENDERS,
      acceptTerms: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    birthdayError() {
      let birthdayErrors = this.errors.filter(e => e.field === 'birthday')
      if (birthdayErrors.length > 0) {
        switch (birthdayErrors[0].key) {
          case 'MaxDate':
            return (
              'A data de nascimento tem de ser inferior a ' +
              this.$options.filters.formatDate(birthdayErrors[0].max_date)
            )
          default:
            return null
        }
      } else {
        return null
      }
    },

    filteredFiles() {
      return this.userData.files.filter(f => f.section == this.league.key)
    }
  },

  watch: {
    '$v.$invalid'(newInvalid) {
      this.$emit('update:isValid', !newInvalid)
    }
  },

  validations() {
    return {
      userData: {
        birthday: { required, min: minValue(this.minBirthdayDate), max: maxValue(this.maxBirthdayDate) },
        gender: {
          required,
          invalid: value => {
            return (
              !helpers.req(value) ||
              this.tournament.gender == 'U' ||
              (this.tournament.gender != 'U' && value == this.tournament.gender)
            )
          }
        },
        phone_number: { required },
        phone_country: { required },
        residence_name: {
          required: requiredIf(() => {
            return this.league?.type == 'inter-residences'
          }),
          max: maxLength(250)
        },
        company_name: {
          required: requiredIf(() => {
            return this.league?.type == '2is'
          }),
          max: maxLength(250)
        },
        student_number: {
          required: requiredIf(() => {
            return this.league?.type == 'alumni'
          }),
          max: maxLength(250)
        }
        // files: {
        //   // temporary turn off files validation until api is ready
        //   required: requiredIf(() => {
        //     return this.validatFiles && this.league?.documents_required && this.league?.documents_required?.length
        //   }),
        // },
      },
      acceptTerms: { accepted: val => this.checkTerms && val === true }
    }
  },
  mounted() {
    if (this.management) {
      this.getUserData()
    } else {
      this.getPrefilledData()
    }
  },
  methods: {
    handleTermsInput(value) {
      console.log('value :>> ', value)
      if (value === true) {
        this.$refs.termsAndConditions.open()
      } else {
        this.acceptTerms = false
      }
    },

    removeFile(file) {
      this.userData.files.splice(this.userData.files.indexOf(file), 1)
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    async uploaded(files) {
      const uploadedFiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            let fileData = file.response.data.file
            fileData['section'] = this.league.key
            uploadedFiles.push(fileData)
          }
        }
      }

      this.$set(this.userData, 'files', this.userData.files.concat(uploadedFiles))
      console.log('this.userData.files :>> ', this.userData.files)
    },

    countryChanged(country) {
      console.log('countryChanged', country)
      this.userData.country_code = country.key
    },

    phoneCountryChanged(country) {
      console.log('phoneCountryChanged', country)
      this.userData.phone_country = country
    },

    setUserData(response) {
      if (!response) return
      let data = response
      if (response.country_code) data.phone_country = response.country_code
      if (response.birthday) data.birthday = new Date(response.birthday)
      if (response.phone_number) data.phone_number = Number(response.phone_number)
      if (!response.files) data.files = []
      if (response.extra_data) data = { ...data, ...response.extra_data }
      if (!data.student_number && this.user.number) data.student_number = this.user.number

      delete data.extra_data
      this.userData = data
    },

    async getPrefilledData() {
      try {
        const response = await this.api.getPrefilledData(this.editionKey)
        console.log('getPrefilledData :>> ', response)
        this.setUserData(response)
      } catch (error) {
        console.log('Error getPrefilledData :>> ', error)
      } finally {
        this.loading = false
      }
    },

    async getUserData() {
      try {
        const response = await this.api.managementGetUserDetails(this.teamKey, this.userKey)
        console.log('getUserData :>> ', response)
        this.setUserData(response)
      } catch (error) {
        console.log('Error getPrefilledData :>> ', error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 750)
      }
    },

    async saveUserData() {
      this.errors = []
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.saving = true

      const userData = JSON.parse(JSON.stringify(this.userData))

      if (this.league?.type == 'inter-residences') {
        // needs "residence_name"
        delete userData.student_number
        delete userData.company_name
      } else if (this.league?.type == '2is') {
        // needs "company_name"
        delete userData.residence_name
        delete userData.student_number
      } else if (this.league?.type == 'alumni') {
        // needs "student_number"
        delete userData.residence_name
        delete userData.company_name
      } else {
        delete userData.student_number
        delete userData.residence_name
        delete userData.company_name
      }

      if (this.userData.birthday) {
        userData.birthday = Dates.toDateString(this.userData.birthday)
      }
      userData['full_phone_number'] = userData['phone_country'].code + userData['phone_number']
      userData['phone_country_code'] = userData['phone_country'].key
      userData['edition_signup_key'] = this.editionKey //userData.key
      delete userData['phone_country']

      console.log('SENDING USER DATA :>> ', userData)
      try {
        const response = userData.key
          ? await this.api.updateSignupEdition(this.editionKey, userData.key, userData)
          : await this.api.createSignupEdition(this.editionKey, userData)
        console.log('saveUserData :>> ', response)

        this.setUserData(response)

        this.$emit('saved')
        this.saving = false
        return true
      } catch (error) {
        console.log('Error saveUserData :>> ', error.response.data['__errors__'])
        let errorData = error.response.data['__errors__']
        this.errors = errorData
        let errorMessage = 'Ocorreu um erro a guardar os dados.'
        if (errorData.length > 0) {
          if (errorData[0].key == 'NotFound') {
            errorMessage = 'Esta edição não foi encontrada.'
          } else if (errorData[0].key == 'AlreadySignedUp') {
            errorMessage = 'Já te inscreveste nesta edição.'
          } else if (errorData[0].key == 'EditionNotPublished') {
            errorMessage = 'Esta edição ainda não está aberta.'
          } else if (errorData[0].key == 'MaxDate') {
            errorMessage =
              'A data de nascimento tem de ser inferior a ' + this.$options.filters.formatDate(errorData[0].max_date)
          } else if (errorData[0].key == 'InvalidOption') {
            errorMessage = 'O género selecionado não é válido.'
          }
        }
        this.$buefy.snackbar.open({
          message: errorMessage,
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
        this.saving = false
        return false
      }
    },

    async isValid() {
      this.$v.userData.$touch()
      return !this.$v.userData.$invalid
    },

    acceptTermsAndConditions() {
      this.acceptTerms = true
    },

    declineTermsAndConditions() {
      this.acceptTerms = false
    }
  }
}
</script>

<i18n lang="json">
  {
    "en": {
      "name": "Nome",
      "email": "E-mail",
      "birthday": "Data de aniversário",
      "gender": "Género",
      "studentNumber": "Número de estudante",
      "companyName": "Nome da empresa",
      "residenceName": "Nome da residência",
      "phoneCountry": "Country code",
      "phone": "Phone number",
      "address": "Address",
      "postalCode": "Postal code",
      "locality": "Locality",
      "country": "Country",
      "documents": "Comprovativo(s) de cumprimento de requisitos",
			"acceptTerms": "I accept the regulations, terms and conditions.",
      "errors": {
        "required": {
          "birthday": "Insira a sua data de nascimento",
          "gender": "Insira o seu género",
          "studentNumber": "Insira o número de estudante",
          "companyName": "Insira o nome da empresa",
          "residenceName": "Insira o nome da residência",
          "phoneNumber": "Phone number is required",
          "address": "Address is required",
          "postalCode": "Postal code is required",
          "locality": "Locality is required",
          "country_code": "Country is required",
          "nif": "NIF is required",
          "phoneCountry": "Country code is required",
					"acceptTerms": "You have to accept the regulations, terms and conditions."
        },
        "min": {
          "birthday": "A data de nascimento tem de ser superior a {date}"
        },
        "max": {
          "birthday": "A data de nascimento tem de ser inferior a {date}"
        },
        "invalid": {
          "studentNumber": "Número de estudante is invalid",
          "companyName": "Nome da empresa is invalid",
          "residenceName": "Nome da residência is invalid",
          "phoneNumber": "Phone number is invalid",
          "address": "Address is invalid",
          "postalCode": "Postal code is invalid",
          "locality": "Locality is invalid",
          "country_code": "Country is invalid",
          "nif": "NIF is invalid",
          "phoneCountry": "Country code is invalid"
        }
      }
    },
    "pt": {
      "name": "Nome",
      "email": "E-mail",
      "birthday": "Data de aniversário",
      "gender": "Género",
      "studentNumber": "Número de estudante",
      "companyName": "Nome da empresa",
      "residenceName": "Nome da residência",
      "phoneCountry": "Indicativo",
      "phone": "Número de telemóvel",
      "address": "Morada",
      "locality": "Localidade",
      "country": "País",
      "postalCode": "Código postal",
      "documents": "Comprovativo(s) de cumprimento de requisitos",
			"acceptTerms": "Aceito os regulamentos, termos e condições.",
      "errors": {
        "required": {
          "birthday": "Insira a sua data de nascimento",
          "gender": "Insira o seu género",
          "studentNumber": "Insira o número de estudante",
          "companyName": "Insira o nome da empresa",
          "residenceName": "Insira o nome da residência",
          "phoneNumber": "O número de telemóvel é obrigatório",
          "address": "A morada é obrigatória",
          "postalCode": "O código postal é obrigatório",
          "locality": "A localidade é obrigatória",
          "country_code": "O país é obrigatório",
          "nif": "O NIF é obrigatório",
          "phoneCountry": "O indicativo é obrigatório",
					"acceptTerms": "Tem que aceitar os regulamentos, termos e condições."
        },
        "min": {
          "birthday": "A data de nascimento tem de ser superior a {date}"
        },
        "max": {
          "birthday": "A data de nascimento tem de ser inferior a {date}"
        },
        "invalid": {
          "studentNumber": "Número de estudante é inválido",
          "companyName": "Nome da empresa é inválido",
          "residenceName": "Nome da residência é inválido",
          "phoneNumber": "O número de telemóvel é inválido",
          "address": "A morada é inválida",
          "postalCode": "O código postal é inválido",
          "locality": "A localidade é inválida",
          "country_code": "O país é inválido",
          "nif": "O NIF é inválido",
          "phoneCountry": "O indicativo é inválido"
        }
      }
    }
  }
</i18n>

<style>
.update-modal-uploader .file-uploads {
  @apply w-full mt-1;
}
</style>
